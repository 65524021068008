.header{
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    max-height: fit-content;

    display: flex;
    flex-direction: row;

    overflow: hidden;
}