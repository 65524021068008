.auth-container {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 250px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.auth-form {
    display: flex;
    flex-direction: column;
}

.auth-form input {
    margin-bottom: 10px;
}

.auth-form button {
    margin-bottom: 10px;
}

.logout-button{
    width: 100%;
    border-radius: 4px;
    padding: 8px;
    background: white;
    border: 1px solid #cccccc;
    cursor: pointer;
    transition: all 0.3s;
}

.logout-button:hover{
    background: #F5F5F5;
}