@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.container {
  position: relative;
  margin: 16px;
  width: calc(100vw - 32px);
  height: calc(100vh - 32px);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}


h1 {
  text-align: center;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: #1890ff;
  font-weight: bold;
}

a:hover {
  color: #40a9ff;
}


form div {
  margin-bottom: 15px;
}

form label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  width: 100%;
  padding: 10px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;
}

form button:hover {
  background-color: #40a9ff;
}


.button-enabled:hover{
  border: 1px solid #1677ff;
  background: #e6f4ff;
}
