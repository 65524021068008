.chat {
    width: 100%;
    height: 15vh;
    max-height: 15vh;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
}

.chat-window {
    height: 196px;
    max-height: 196px;
    border: 1px solid #ccc;
    padding: 10px;
    overflow-y: auto;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #fafafa;
}

.chat-input {
    display: flex;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-right: 10px;
}

.chat-input button {
    padding: 10px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
}

.chat-input button:hover {
    background-color: #40a9ff;
}
