.teams-panel {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 10px;
    width: fit-content;
    margin-right: auto;
}

h2 {
    font-size: 1vw;
}

.team-container {
    width: fit-content;
    display: grid;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fafafa;
    border: 1px solid #cccccc;
}

.team-info {
    width: fit-content;
    grid-column: 1 / 1 ;
    grid-row: 1 / 1;

    margin: 10px;

    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    text-align: center;
}

.team-info-text {
    color: #4c4c4c;
    font-size: 1vw;
    line-height: 1vw;
    font-weight: 800;
}

.join-team-button {
    grid-column: 1 /1;
    grid-row: 1 / 1;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: transparent;
    line-height: 1vw;
    font-size: 1vw;
    font-weight: 800;
    transition: all 0.3s;
    cursor: pointer;
}


.join-team-button:hover {
    color: white;
    background: var(--fill-color);
}