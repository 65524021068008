.end-game-background {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    margin: -16px;

    align-items: center;
    justify-content: center;

    background: #24242424;
}

.end-game-popup h1 {
    margin: 0;
}

.end-game-popup{
    display: flex;
    flex-direction: column;

    gap: 0.5vw;

    width: 10vw;
    height: fit-content;
    min-height: 100px;

    padding: 0.5vw;

    background: white;
    border: 1px solid #cccccc;
    border-radius: 8px;
}

.popup-button-blue{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2vw;

    border-radius: 4px;

    font-weight: 600;
    font-size: 1vw;
    color: white;

    background: #1677ff;
    cursor: pointer;
    transition: all 0.3s;
}

.popup-button-blue:hover{
    background: #40a9ff;
}