.card-text {
    grid-row: 1;
    grid-column: 1;
}

.game-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5vw;
    width: 100%;
}

.card-votes {
    grid-row: 1;
    grid-column: 1;

    display: flex;
    align-items: end;
    justify-content: end;

    font-size: 1vw;

    width: 10.4vw;
    height: calc(100%);
    margin-bottom: 8px;
    margin-right: 12px;
}

.card {
    transition: all 0.3s;
    display: grid;

    align-items: center;
    justify-content: center;

    border: 1px solid #ccc;

    width: 10.4vw;
    height: 5.4vw;

    text-align: center;
    border-radius: 8px;
    background-color: #fafafa;
    font-size: 1.2vw;

    color: #242424;
    cursor: pointer;

    font-weight: 900;
}