.ready-button-container{
    display: grid;
    position: absolute;
    left: calc(45vw);
    width: 10vw;
    height: fit-content;
    border-radius: 8px;
    background-color: #fafafa;
    border: 1px solid #cccccc;

    overflow: hidden;

    font-weight: 800;
    font-size: 1vw;
    color: #4c4c4c;
}

.ready-button {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1;
    grid-column: 1;

    color: transparent;
    transition: all 0.3s;
    cursor: pointer;
}

.ready-button:hover {
    color: white;
    background: #4c4c4c;
}

.ready-info {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1;
    grid-column: 1;

}